<template>
	<v-container>
		<v-row>
			<v-col cols="12" sm="6" md="8" class="ml-auto mr-auto pl-0">
				<v-list shaped>
					<v-subheader>Services</v-subheader>
					<v-list-item v-for="(item, i) in services" :key="i">
						<v-list-item-content>
							<v-list-item-title>{{item}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
export default {
	data: () => {
		return {
			services: [
				"Brake work",
				"Front end work",
				"All suspension",
				"Exhaust work (repairs and custom)",
				"Fuel system service and repair",
				"Tune ups ",
				"Engine decarb",
				"Check engine light diagnostics ",
				"Engine swaps and upgrades ",
				"Drivetrain service and repairs",
				"All factory fluid flushes",
				"Tires, rims, new and repairs",
				"Winter rim packages",
				"Oil changes (syn and reg)",
				"Electrical system diagnostic and repairs",
				"Batteries, starters, alternators",
				"Welding",
				"Tuner and performance upgrades",
				"Remote starter install and programming ",
				"MTO approved safety certifications",
				"MTO approved commercial inspections",
				"Clutch work",
				"Strange Noise",
				"Trailer hitches and electrical",
				"Personalized maintenance packages",
				"Complete check over packages",
				"Pre purchase inspections",
				"Undercoating",
				"Alignments (not in house)",
				"Windshields replaced(cracked or broken)",
				"Windshield chips filled",
				"Window tinting ",
				"Stereo installs",
				"If not on the list... Just ask!",
			],
		};
	},
};
</script>